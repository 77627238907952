import { BaseApi, ApiSchema, AxiosProvider, nestedParamsSerializer } from '@/libs/api';
import { schema } from './schema';
import { createLogger } from '@/uikit/util/logger';
const logger = createLogger('PageNotifications', 'blue');

const apiSchema = new ApiSchema(schema);
const axiosProvider = new AxiosProvider({
  baseUrl: apiSchema.getBaseUrl(),
  paramsSerializer: nestedParamsSerializer,
});

class SeoEventService extends BaseApi {
  async triggerEvent(assetId, name, labels) {
    const { data } = await this.fetch('register_event', {
      asset_id: assetId,
      name: name,
      labels: labels,
    });
    return data;
  }

  async safeTriggerEvent(assetId, name, labels) {
    try {
      return await this.triggerEvent(assetId, name, labels);
    } catch (error) {
      logger.error('Ошибка регистрации события', assetId, name, labels);
    }
  }
}

const service = new SeoEventService(apiSchema, axiosProvider);

export { SeoEventService };
export { service };
export { logger };
export default service;

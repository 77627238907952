// см. #4426@house
import { service as seoEventService } from '@/services/seo-events/seo-event-service.js';

export function clickSBClient(assetId) {
  seoEventService.safeTriggerEvent(assetId, 'credit_potential', ['client', 'web_mobile']);

  window.yaCounter49130266?.reachGoal('сredit_potential_sbclient');
  window.open(
    'https://sbi.sberbank.ru:9443/ic/ufs/index.html#/credits/credit-financing/create?category=CREDIT_POTENTIAL&partnerCode=partnerportaldaclcreditpotentional',
    '_blank'
  );
}

export function clickCompany(assetId) {
  seoEventService.safeTriggerEvent(assetId, 'credit_potential', ['ul', 'not_client', 'web_mobile']);
  window.yaCounter49130266?.reachGoal('сredit_potential_otherclient_company');
  const formattedDate = new Date().toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }).replace(/\//g, '_'); 
  window.open(
    `https://www.sberbank.com/ru/s_m_business/credits/potential?utm_source=portal_da&utm_medium=referral&utm_campaign=${formattedDate}`,
    '_blank'
  );
}

export function clickIp(assetId) {
  seoEventService.safeTriggerEvent(assetId, 'credit_potential', ['ip', 'not_client', 'web_mobile']);
  window.yaCounter49130266?.reachGoal('сredit_potential_otherclient_IP');
  window.open('https://www.sberbank.com/sms/smallbusiness?source=partnerportalda', '_blank');
}

export function clickSBClientLand(assetId) {
  seoEventService.safeTriggerEvent(assetId, 'credit_potential', [
    'landing',
    'client',
    'web_mobile',
  ]);
  window.yaCounter49130266?.reachGoal('сredit_potential_sbclient_land');
  window.open(
    'https://sbi.sberbank.ru:9443/ic/ufs/index.html#/credits/credit-financing/create?category=CREDIT_POTENTIAL&partnerCode=partnerportaldaclcreditpotentionalservice',
    '_blank'
  );
}

export function clickCompanyLand(assetId) {
  seoEventService.safeTriggerEvent(assetId, 'credit_potential', [
    'landing',
    'ul',
    'not_client',
    'web_mobile',
  ]);
  window.yaCounter49130266?.reachGoal('сredit_potential_otherclient_company_land');
  const formattedDate = new Date().toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }).replace(/\//g, '_');
  window.open(
    `https://www.sberbank.com/ru/s_m_business/credits/potential?utm_source=portal_da_service&utm_medium=referral&utm_campaign=${formattedDate}`,
    '_blank'
  );
}

export function clickIpLand(assetId) {
  seoEventService.safeTriggerEvent(assetId, 'credit_potential', [
    'landing',
    'ip',
    'not_client',
    'web_mobile',
  ]);
  window.yaCounter49130266?.reachGoal('сredit_potential_otherclient_IP_land');
  window.open(
    'https://www.sberbank.com/sms/businesspotential?source=partnerportaldacreditpotentialservice',
    '_blank'
  );
}

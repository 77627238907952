export const schema = {
  baseUrl: process.env.VUE_APP_API_HOST + '/api/v2',
  api: {
    register_event: {
      request: {
        method: 'post',
        url: '/seo_events.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },
  },
};

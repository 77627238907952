<template lang="pug">
v-bottom-sheet(v-model="isOpen" content-class="credit-potential-apply-dialog")
  v-toolbar.credit-potential-apply-dialog__header(flat height=48)
    .t-h3-mobile.c-gray100 Выберите статус бизнеса
    v-spacer
    ui-btn(plain width=32 @click="close")
      ui-icon.c-gray70(name="close")

  .credit-potential-apply-dialog__footer
    ui-btn.ui-append-icon.mb-4(
      large
      secondary
      width="350"
      @click="$emit('click:company')"
    )
      | Юридическое лицо
      .ui-append-icon__icon
        ui-icon.c-gray70(name="open-in-new")
    ui-btn.ui-append-icon(
      large
      secondary
      width="350"
      @click="$emit('click:ip')"
    )
      | ИП
      .ui-append-icon__icon
        ui-icon.c-gray70(name="open-in-new")
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';

  export default {
    components: { UiBtn, UiIcon },

    data() {
      return {
        isOpen: false,
      };
    },

    methods: {
      open() {
        this.isOpen = true;
      },

      close() {
        this.isOpen = false;
      },
    },
  };
</script>
<style lang="scss">
  .credit-potential-apply-dialog {
    background-color: c('white', 'light');
  }

  .credit-potential-apply-dialog__header {
    height: 48px;
  }

  .credit-potential-apply-dialog__content {
    display: flex;
    padding: 0 48px 0 16px;
  }

  .credit-potential-apply-dialog__footer {
    padding: 40px 16px 40px 16px;
  }
</style>

<template lang="pug">
ui-dialog(
  v-if="state === 'new'"
  v-model="isOpen"
  content-class="letter-of-credit-apply-dialog"
  fullscreen
)
  template(#header)
    ui-dialog-header(
      close-btn
      title="Заявка на безопасную сделку"
      :rounded="false"
      @click:close="close"
    )

  .px-4.pt-6
    .t-normal.c-gray80.mb-6 Вы можете оставить заявку, чтобы сотрудник СберБанка проконсультировал по&nbsp;безопасным расчетам с&nbsp;вашим контрагентом

    .t-caption-xsmall.c-gray70.mb-1 Вы покупаете как
    ui-btn-toggle-checkbox.mb-6(v-model="application.kind" mandatory)
      ui-btn.justify-start(value="natural" width=280)
        .ui-btn-toggle-checkbox__icon
        | Физическое лицо
      ui-btn.justify-start(value="legal" width=280)
        .ui-btn-toggle-checkbox__icon
        | Юридическое лицо или ИП

    v-row.mt-0
      v-col.pt-2.pb-6(cols="12")
        v-text-field(
          v-model="application.name"
          label="ФИО"
          autocomplete="off"
          hide-details="auto"
          key="name"
          persistent-placeholder
          validate-on-blur
          :error-messages="errors.name"
          :rules="[valid.notEmpty]"
        )
    v-row
      v-col.pt-2.pb-6(cols=12 sm=6)
        v-text-field(
          v-model="application.phone"
          label="Мобильный телефон"
          autocomplete="off"
          hide-details="auto"
          key="phone"
          persistent-placeholder
          validate-on-blur
          :error-messages="errors.phone"
          :rules="[valid.notEmpty]"
        )
    v-row.mt-6
      v-col.pt-2.pb-6(cols=12 v-if="application.kind === 'legal'" sm=6)
        v-text-field(
          v-model="application.inn"
          label="ИНН"
          placeholder="10 или 12-ти значный ИНН ЮЛ/ИП"
          autocomplete="off"
          hide-details="auto"
          key="phone"
          persistent-placeholder
          validate-on-blur
          :error-messages="errors.inn"
          :rules="[valid.notEmpty]"
        )
    v-row.mt-6
      v-col.py-2
        v-textarea.textarea--lined(
          v-model="application.comment"
          label="Комментарий (не обязательно)"
          autocomplete="off"
          counter
          hide-details="auto"
          key="comment"
          no-resize
          persistent-placeholder
          rows=10
          :error-messages="errors.comment"
          :rules="[valid.tooLongComment]"
        )

  template(#footer)
    .letter-of-credit-apply-dialog-footer
      .d-flex.align-start.mb-5
        v-checkbox.c-primary.ma-0.pa-0.flex-grow-0.mr-1(
          v-model="application.accept_personal_data"
          hide-details
          :error="hasError('accept_personal_data')"
          :rules="[valid.beAccepted]"
          @click="clearError('accept_personal_data')"
        )
        .t-small.flex-grow-1.c-gray80 Я согласен с&nbsp;<a class="c-primary link link-icon-open-window link-icon--small" href="https://www.sberbank.ru/privacy/policy" target="_blank">условиями</a> использования банком моих персональных данных для&nbsp;обработки данного обращения

      ui-btn(
        large
        primary
        width="100%"
        @click="submitApplication"
        :disabled="loading"
      )
        span(v-if="!loading") Отправить заявку
        ui-loader(v-else)

v-bottom-sheet(
  v-else-if="state === 'success'"
  v-model="isOpen"
  content-class="letter-of-credit-success-dialog"
  width="100%"
)
  ui-dialog-header(
    close-btn
    flat
    transparent
    @click:close="close"
  )

  .letter-of-credit-success-dialog__content
    .d-flex.mb-12
      ui-icon.c-primary.mr-3(name="check-round-full" size=48)
      div
        .t-h4-mobile.c-gray100.my-3 Ваша заявка №{{ existingApplication.number }} от&nbsp;{{ existingApplication.created_at | dateFormal }} принята в&nbsp;обработку!
        .t-middle.c-gray80
          | Специалист банка свяжется с&nbsp;Вами в&nbsp;ближайшее время и&nbsp;поможет оформить документы для&nbsp;сделки. Если у&nbsp;Вас остались вопросы, Вы можете позвонить по&nbsp;номеру <strong>8&nbsp;800&nbsp;200&nbsp;86&nbsp;03</strong> или&nbsp;узнать больше о&nbsp;продукте на&nbsp;<a class="c-primary link link-icon-open-window link-icon--small" href="https://www.sberbank.com/ru/person/payments/special_acc/accreditive" target="_blank">сайте Сбербанка</a>
</template>

<script>
  import { UiBtn, UiIcon, UiLoader, UiBtnToggleCheckbox, UiDialog, UiDialogHeader } from '@/uikit';
  import { handleError } from '@/libs/handle-error';
  import { formErrors } from '@/uikit/mixins/formErrors';
  import { service as ServicesAPI } from '@/services/services';

  const COMMENT_LENGTH_MAX = 1000; // как на бекенде

  export default {
    components: { UiBtn, UiIcon, UiLoader, UiBtnToggleCheckbox, UiDialog, UiDialogHeader },

    mixins: [formErrors],
    props: {
      assetId: { type: String, default: undefined },
    },

    data() {
      return {
        isOpen: false,
        loading: false,
        application: {},
        existingApplication: {},
        state: 'new', // new/success
        accepted: false,
        valid: {
          notEmpty: (value) => !!value || 'Должно быть заполнено',
          beAccepted: (value) => value === undefined || value == true || 'Должно быть принято',
          tooLongComment: (value) =>
            !value ||
            value.length <= COMMENT_LENGTH_MAX ||
            `Слишком большой текст, максимум ${COMMENT_LENGTH_MAX} знаков`,
        },
      };
    },

    methods: {
      open() {
        this.initialize();
        this.isOpen = true;
      },

      async initialize() {
        try {
          this.state = 'new';
          this.loading = true;
          this.application = await ServicesAPI.newLetterOfCredit(this.assetId);
          this.application.accept_personal_data = undefined;
        } catch (error) {
          handleError(error);
        } finally {
          this.loading = false;
        }
      },

      async submitApplication() {
        this.clearErrors();
        try {
          this.loading = true;
          const existingApplication = await ServicesAPI.createLetterOfCreditApplication({
            ...this.application,
            asset_id: this.assetId,
          });
          this.state = 'success';
          this.existingApplication = existingApplication;
        } catch (error) {
          handleError(error);
          if (error.data?.code === 'invalid_form') {
            this.errors = error.data.errors;
          }
        } finally {
          this.loading = false;
        }
      },

      close() {
        this.isOpen = false;
        this.application = {};
        this.state = 'new';
      },
    },
  };
</script>

<style lang="scss">
  .letter-of-credit-apply-dialog {
    background: c('white');
  }

  .letter-of-credit-apply-dialog-footer {
    margin: -12px -16px;
    padding: 16px 16px 12px;
    background-color: c('gray5');
  }

  .letter-of-credit-success-dialog {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    background-color: c('white', 'light');

    .letter-of-credit-success-dialog__content {
      display: flex;
      padding: 0 48px 0 16px;
    }
  }
</style>
